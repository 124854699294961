<template>
  <div class="animated fadeIn">
    <b-container>
      <b-row class="mt-5">
        <b-col>
          <b-card>
            <b-progress
              :value="index"
              :max="accounts.length"
              show-progress
              animated
            ></b-progress>
            <b-button
              block
              variant="primary"
              @click="start()"
              size="lg"
              v-if="!is_start"
              :disabled="is_run"
              >start</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "re-payment-error",
  data: function() {
    return {
      isLoading: false,
      is_start: false,
      is_run: false,
      restart_list: [],
      accounts: [],
      value: 0,
      index: 0,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load: function() {
      this.$http
        .get(
          this.$config.API_URL + "ReportWebService/test_interest_error_list",
          {
            params: { date: "2019-08-25" },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.$data.accounts = response.body.data;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    start: function() {
      this.$data.is_start = true;
      this.run();
    },
    run: function() {
      this.$data.is_run = true;
      this.$http
        .get(this.$config.API_URL + "ReportWebService/test_interest_error", {
          params: { id: this.$data.accounts[this.$data.index] },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.is_run = false;
            //console.log(response)
            this.$data.index++;
            if (this.$data.index < this.$data.accounts.length) {
              this.run();
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
